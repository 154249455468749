import React, { createContext, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logoutGoogle } from "../api/ApiService";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(() => {
    const storedUserInfo = localStorage.getItem("userInfo");
    return storedUserInfo
      ? JSON.parse(storedUserInfo)
      : { name: null, email: null, avatar: null, nickName: null, roles: [] };
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo.name && userInfo.email && userInfo.avatar) {
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    }
  }, [userInfo]);

  const updateUserInfo = async (newInfo) => {
    if (
      newInfo.avatar &&
      newInfo.avatar.startsWith("http") &&
      !localStorage.getItem("isFetchAvatar")
    ) {
      try {
        localStorage.setItem("isFetchAvatar", 1);
        const response = await fetch(newInfo.avatar);
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("text/html")) {
          console.warn("Avatar URL returned HTML content, skipping save.");
          return;
        }

        const blob = await response.blob();
        const reader = new FileReader();

        reader.onloadend = () => {
          const base64data = reader.result;
          localStorage.setItem("profilePicB64", base64data);
          setUserInfo({ ...newInfo, avatar: base64data });
        };

        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error converting avatar to base64:", error);
      }
    } else {
      setUserInfo(newInfo);
    }
  };

  const logout = async (isSilent = false) => {
    if (localStorage.getItem("googleToken")) {
      await logoutGoogle();
    }
    if (localStorage.getItem("metaToken")) {
      //await logoutMeta();
    }
    setUserInfo({
      name: null,
      email: null,
      avatar: null,
      nickName: null,
      roles: [],
    });

    [
      "callFetchToken",
      "currentQuestion",
      "internalToken",
      "isSurveyLoaded",
      "isFetchAvatar",
      "googleToken",
      "metaToken",
      "profilePicB64",
      "surveyResponse",
      "userHeight",
      "userInfo",
      "userWaist",
      "userWeight",
    ].forEach((item) => {
      localStorage.removeItem(item);
    });

    if (!isSilent) navigate("/");
  };

  return (
    <UserContext.Provider
      value={{ userInfo, setUserInfo: updateUserInfo, logout }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
