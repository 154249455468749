// import { useAuth } from "../store/AuthContext";
import axios from "axios";
import { useUser } from "../store/UserContext";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 15000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const UseApi = () => {
  const { logout } = useUser();

  const getWithAuth = async (url, provider, params = {}) => {
    try {
      const token = localStorage.getItem(provider + "Token");
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      const response = await apiClient.get(url, { params, headers });
      return response;
    } catch (err) {
      if (err.response && [403, 401].includes(err.response.status)) {
        console.log("Unauthorized, logging out...");
        logout();
      } else {
        console.log(err);
      }
      return;
    }
  };

  const postWithAuth = async (url, provider, data) => {
    try {
      const token = localStorage.getItem(provider + "Token");
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      const response = await apiClient.post(url, data, { headers });
      return response;
    } catch (err) {
      if (err.response && [403, 401].includes(err.response.status)) {
        console.log("Unauthorized, logging out...");
        logout();
      } else {
        console.log(err);
      }
      return;
    }
  };

  return { getWithAuth, postWithAuth };
};
