import React, { useState, useEffect } from "react";
import { upsertAnswer } from "../../util/Util";

const AIPicker = ({
  surveyResponse,
  setSurveyResponse,
  completeSurvey,
  handleBack,
  saveUpsert,
}) => {
  const [selectedHelpOptions, setSelectedHelpOptions] = useState([]);
  const [otherText, setOtherText] = useState("");

  useEffect(() => {
    const storedResponse = JSON.parse(localStorage.getItem("surveyResponse"));
    if (storedResponse && storedResponse.survey_data) {
      const questionResponse = storedResponse.survey_data.find(
        (response) => response.questionNumber === 1000,
      );
      if (questionResponse) {
        setSelectedHelpOptions(questionResponse.answerList || []);
        setOtherText(questionResponse.answerString || "");
      }
    }
  }, []);
  const updateSurveyResponse = (options, text, isComplete = false) => {
    const answerString = options.includes("Others") ? text : "";
    upsertAnswer(1000, answerString, options, setSurveyResponse);

    const updatedSurveyData = surveyResponse.survey_data.map((response) => {
      if (response.questionNumber === 1000) {
        return { questionNumber: 1000, answerString, answerList: options };
      }
      if (isComplete && response.questionNumber === 8888) {
        return {
          questionNumber: 8888,
          answerString: "completed",
          answerList: [],
        };
      }
      return response;
    });

    if (
      isComplete &&
      !updatedSurveyData.some((response) => response.questionNumber === 8888)
    ) {
      updatedSurveyData.push({
        questionNumber: 8888,
        answerString: "completed",
        answerList: [],
      });
    }

    const updatedResponse = {
      ...surveyResponse,
      survey_data: updatedSurveyData,
    };

    localStorage.setItem("surveyResponse", JSON.stringify(updatedResponse));
  };

  const handleOptionToggle = (option) => {
    setSelectedHelpOptions((prevOptions) => {
      const newOptions = prevOptions.includes(option)
        ? prevOptions.filter((opt) => opt !== option)
        : [...prevOptions, option];
      updateSurveyResponse(newOptions, otherText);
      return newOptions;
    });
  };

  const handleOtherTextChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9\s.,()\-:‘’"'"]*$/;
    let filteredValue = value
      .split("")
      .filter((char) => regex.test(char))
      .join("");
    if (filteredValue.length > 1000) {
      filteredValue = filteredValue.substring(0, 1000);
    }
    setOtherText(filteredValue);
    updateSurveyResponse(selectedHelpOptions, filteredValue);
  };

  const validateAndCompleteSurvey = () => {
    if (selectedHelpOptions.length === 0) {
      return;
    }

    if (selectedHelpOptions.includes("Others") && otherText.trim() === "") {
      return;
    }

    const answerString = selectedHelpOptions.includes("Others")
      ? otherText
      : "";
    upsertAnswer(1000, answerString, selectedHelpOptions, setSurveyResponse);
    upsertAnswer(8888, "completed", [], setSurveyResponse);
    updateSurveyResponse(selectedHelpOptions, otherText, true);
    // let defineSurveyData = surveyResponse.survey_data;
    saveUpsert();
    console.log("Survey completed with selections:", selectedHelpOptions);
    completeSurvey();
  };

  const optionMapper = {
    "Track fat loss": "📉Track fat loss",
    "Dietary advice": "🥗Dietary advice",
    "Meal and recipes": "🍽️Meals/recipes",
    "Exercise planning": "🏋️Exercise plan",
    "Calorie estimation": "📊Calorie estimates",
    "Lifestyle advice": "🧘Lifestyle advice",
    Minigames: "🎮Minigames",
    None: "🚫None",
    Others: "📝Others",
  };
  return (
    <>
      <div className="flex justify-center">
        <img
          src="chat2/05-cute-chatbot.jpg"
          alt="healthChat"
          className="w-16 h-16 rounded-full object-cover"
        />
      </div>
      <div className="flex justify-center">
        <div className="text-center text-xl font-thin text-blue-600">
          What do you think
          <br />
          I, healthChat, <br />
          can help you with?
        </div>
      </div>

      {/*<div className="flex justify-center">
        <div className="text-center text-xl font-thin text-blue-600">
          <Typewriter
            options={{
              strings: ["I, healthChat, <br />can help you with?"],
              autoStart: true,
              loop: false,
              deleteSpeed: Infinity,
              cursor: "",
            }}
          />
        </div>
      </div>*/}
      <div className="flex flex-wrap justify-center mt-4 gap-2">
        {Object.keys(optionMapper).map((option) => (
          <button
            key={option}
            onClick={() => handleOptionToggle(option)}
            className={`px-4 py-2 rounded ${
              selectedHelpOptions.includes(option)
                ? "bg-blue-500 text-white"
                : "bg-gray-300 text-black"
            }`}
          >
            {optionMapper[option]}
          </button>
        ))}
      </div>
      {selectedHelpOptions.includes("Others") && (
        <div className="flex justify-center mt-4">
          <textarea
            value={otherText}
            onChange={handleOtherTextChange}
            placeholder="Please specify"
            className="border border-gray-300 rounded px-2 py-1 w-full"
            rows={4}
            maxLength={1000} // This also helps prevent further typing beyond 1000 chars
          />
        </div>
      )}
      <div className="flex justify-center mt-4">
        <button
          type="button"
          onClick={handleBack}
          className="mt-2 px-4 py-2 bg-gray-500 text-white rounded mr-2"
        >
          Back
        </button>
        <button
          type="button"
          onClick={validateAndCompleteSurvey}
          className={`mt-2 px-4 py-2 rounded ${
            selectedHelpOptions.length === 0 ||
            (selectedHelpOptions.includes("Others") && otherText.trim() === "")
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "bg-blue-500 text-white"
          }`}
          disabled={
            selectedHelpOptions.length === 0 ||
            (selectedHelpOptions.includes("Others") && otherText.trim() === "")
          }
        >
          Finish
        </button>
      </div>
    </>
  );
};

export default AIPicker;
