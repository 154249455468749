import React, { useEffect } from "react";

const Terms = ({ setIsAllowedBackHome }) => {
  useEffect(() => {
    // Simulate an asynchronous operation
    // This could be an API call to check if the user is allowed back home
    const isAllowed = true; // Replace with your actual logic

    // Call the props function to set the state in the parent component
    setIsAllowedBackHome(isAllowed);
  }, [setIsAllowedBackHome]);
  return (
    <div>
      <h1>Terms of Service</h1>
      {/* Your terms of service content here */}
    </div>
  );
};

export default Terms;
