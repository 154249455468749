import React from "react";
import { Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../store/UserContext";

const UserLoginMenu = ({ anchorEl, handleClose }) => {
  const { userInfo, logout } = useUser();
  const navigate = useNavigate();

  const handleLogin = () => {
    logout(true);
    handleClose();
    navigate("/survey");
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl) && !Boolean(userInfo.name)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleLogin}>Login</MenuItem>
    </Menu>
  );
};

export default UserLoginMenu;
