import React, { useEffect } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Home from "./component/Home";
import Survey from "./component/Survey";
import Start from "./component/start/Start";
import Privacy from "./component/start/Privacy";
import Terms from "./component/start/Terms";
import Debug from "./component/tell-me-more/Debug";
import Translate from "./translate/Translate";
import TestChat from "./component/chat/TestChat";
import ResearchAdmin from "./component/research-admin/ResearchAdmin";
import Store from "./store/Store";
import { UserProvider, useUser } from "./store/UserContext";
import { jwtDecode } from "jwt-decode";
import UserLogoutMenu from "./component/avatar/UserLogoutMenu";
import {
  fetchGoogleToken,
  fetchMetaToken,
  // fetchUserInfoWithToken,
} from "./api/ApiService";
import UserAvatarButton from "./component/avatar/UserAvatarButton";
import DailyMission from "./component/mission/DailyMission";
import UserLoginMenu from "./component/avatar/UserLoginMenu";

const App = () => {
  const { userInfo, setUserInfo } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [avatarAnchorEl, setAvatarAnchorEl] = React.useState(null);
  const [isAllowedBackHome, setIsAllowedBackHome] = React.useState(false);

  // const currentPage = useSelector((state) => state.app.currentPage);
  useEffect(() => {
    // const googleToken = localStorage.getItem("googleToken");
    let callFetchToken = localStorage.getItem("callFetchToken");
    if (!callFetchToken) {
      const metaAuthCode = new URLSearchParams(window.location.search).get(
        "metaAuthCode",
      );
      const googleAuthCode = new URLSearchParams(window.location.search).get(
        "googleAuthCode",
      );
      const fetchUserInfo = async () => {
        if (metaAuthCode) {
          try {
            const response = await fetchMetaToken(metaAuthCode);
            let { name, email, access_token, internal_token, avatar } =
              response.data;
            localStorage.setItem("metaToken", access_token);
            localStorage.setItem("internalToken", internal_token);

            const decodedToken = jwtDecode(internal_token);
            let rolesArray = decodedToken?.roles || [];
            avatar = `https://graph.facebook.com/me/picture?type=square&width=64&height=64&access_token=${access_token}`;

            setUserInfo({
              name,
              email,
              avatar,
              nickName: null,
              roles: rolesArray,
            });
            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                name,
                email,
                avatar,
                nickName: null,
                roles: rolesArray,
              }),
            );
          } catch (error) {
            console.error("Error fetching meta token:", error);
          }
        } else if (googleAuthCode) {
          try {
            const response = await fetchGoogleToken(googleAuthCode);
            const { name, email, access_token, internal_token, avatar } =
              response.data;
            localStorage.setItem("googleToken", access_token);
            localStorage.setItem("internalToken", internal_token);

            const decodedToken = jwtDecode(internal_token);
            let rolesArray = decodedToken?.roles || [];

            setUserInfo({
              name,
              email,
              avatar,
              nickName: null,
              roles: rolesArray,
            });
            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                name,
                email,
                avatar,
                nickName: null,
                roles: rolesArray,
              }),
            );
          } catch (error) {
            console.error("Error fetching google token:", error);
          }
        }
        // }
      };

      if (
        (metaAuthCode || googleAuthCode) &&
        (!userInfo.name || !userInfo.email) &&
        !localStorage.getItem("callFetchToken")
      ) {
        localStorage.setItem("callFetchToken", 1);
        fetchUserInfo();
      }
    }
  }, [userInfo, setUserInfo, navigate]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAvatarMenu = (event) => {
    setAvatarAnchorEl(event.currentTarget);
  };

  const handleTitleClick = () => {
    if (isAllowedBackHome) {
      navigate("/");
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAvatarAnchorEl(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
    handleClose();
  };

  return (
    <div>
      <AppBar position="static">
        {location.pathname !== "/start" && (
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              style={{ flexGrow: 1 }}
              className="font-thin"
              onClick={handleTitleClick}
            >
              healthChat
            </Typography>
            <UserAvatarButton
              handleAvatarMenu={handleAvatarMenu}
              anchorEl={avatarAnchorEl}
            />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleNavigation("/")}>Home</MenuItem>
              <MenuItem onClick={() => handleNavigation("/survey")}>
                Survey
              </MenuItem>
              <MenuItem onClick={() => handleNavigation("/privacy")}>
                Privacy
              </MenuItem>
              <MenuItem onClick={() => handleNavigation("/terms")}>
                Terms
              </MenuItem>
              <MenuItem onClick={() => handleNavigation("/testchat")}>
                Test Chat
              </MenuItem>
              {userInfo &&
                userInfo.roles &&
                userInfo.roles.includes("researchAdmin") && (
                  <MenuItem onClick={() => handleNavigation("/research-admin")}>
                    Research Admin
                  </MenuItem>
                )}
            </Menu>
            <UserLogoutMenu
              anchorEl={avatarAnchorEl}
              handleClose={handleClose}
            />
            <UserLoginMenu
              anchorEl={avatarAnchorEl}
              handleClose={handleClose}
            />
          </Toolbar>
        )}
      </AppBar>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/start" element={<Start />} />
        <Route
          path="/survey"
          element={<Survey setIsAllowedBackHome={setIsAllowedBackHome} />}
        />
        <Route path="/privacy" element={<Privacy />} />
        <Route
          path="/terms"
          element={<Terms setIsAllowedBackHome={setIsAllowedBackHome} />}
        />
        <Route path="/translate" element={<Translate />} />
        <Route path="/debug" element={<Debug />} />
        <Route path="/daily" element={<DailyMission />} />
        <Route
          path="/testchat"
          element={<TestChat setIsAllowedBackHome={setIsAllowedBackHome} />}
        />
        <Route path="/research-admin" element={<ResearchAdmin />} />
      </Routes>
    </div>
  );
};

const AppWrapper = () => (
  <Provider store={Store}>
    <Router>
      <UserProvider>
        <App />
      </UserProvider>
    </Router>
  </Provider>
);

export default AppWrapper;
