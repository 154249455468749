const CountryCodes = [
  { country: "AF", code: "+93", flag: "🇦🇫", isoCode: "AF" },
  { country: "AL", code: "+355", flag: "🇦🇱", isoCode: "AL" },
  { country: "DZ", code: "+213", flag: "🇩🇿", isoCode: "DZ" },
  { country: "AS", code: "+1684", flag: "🇦🇸", isoCode: "AS" },
  { country: "AD", code: "+376", flag: "🇦🇩", isoCode: "AD" },
  { country: "AO", code: "+244", flag: "🇦🇴", isoCode: "AO" },
  { country: "AI", code: "+1264", flag: "🇦🇮", isoCode: "AI" },
  { country: "AG", code: "+1268", flag: "🇦🇬", isoCode: "AG" },
  { country: "AR", code: "+54", flag: "🇦🇷", isoCode: "AR" },
  { country: "AM", code: "+374", flag: "🇦🇲", isoCode: "AM" },
  { country: "AU", code: "+61", flag: "🇦🇺", isoCode: "AU" },
  { country: "AT", code: "+43", flag: "🇦🇹", isoCode: "AT" },
  { country: "AZ", code: "+994", flag: "🇦🇿", isoCode: "AZ" },
  { country: "BS", code: "+1242", flag: "🇧🇸", isoCode: "BS" },
  { country: "BH", code: "+973", flag: "🇧🇭", isoCode: "BH" },
  { country: "BD", code: "+880", flag: "🇧🇩", isoCode: "BD" },
  { country: "BB", code: "+1246", flag: "🇧🇧", isoCode: "BB" },
  { country: "BY", code: "+375", flag: "🇧🇾", isoCode: "BY" },
  { country: "BE", code: "+32", flag: "🇧🇪", isoCode: "BE" },
  { country: "BZ", code: "+501", flag: "🇧🇿", isoCode: "BZ" },
  { country: "BJ", code: "+229", flag: "🇧🇯", isoCode: "BJ" },
  { country: "BM", code: "+1441", flag: "🇧🇲", isoCode: "BM" },
  { country: "BT", code: "+975", flag: "🇧🇹", isoCode: "BT" },
  { country: "BO", code: "+591", flag: "🇧🇴", isoCode: "BO" },
  { country: "BA", code: "+387", flag: "🇧🇦", isoCode: "BA" },
  { country: "BW", code: "+267", flag: "🇧🇼", isoCode: "BW" },
  { country: "BR", code: "+55", flag: "🇧🇷", isoCode: "BR" },
  { country: "BN", code: "+673", flag: "🇧🇳", isoCode: "BN" },
  { country: "BG", code: "+359", flag: "🇧🇬", isoCode: "BG" },
  { country: "BF", code: "+226", flag: "🇧🇫", isoCode: "BF" },
  { country: "BI", code: "+257", flag: "🇧🇮", isoCode: "BI" },
  { country: "KH", code: "+855", flag: "🇰🇭", isoCode: "KH" },
  { country: "CM", code: "+237", flag: "🇨🇲", isoCode: "CM" },
  { country: "CA", code: "+1", flag: "🇨🇦", isoCode: "CA" },
  { country: "CV", code: "+238", flag: "🇨🇻", isoCode: "CV" },
  { country: "KY", code: "+1345", flag: "🇰🇾", isoCode: "KY" },
  { country: "CF", code: "+236", flag: "🇨🇫", isoCode: "CF" },
  { country: "TD", code: "+235", flag: "🇹🇩", isoCode: "TD" },
  { country: "CL", code: "+56", flag: "🇨🇱", isoCode: "CL" },
  { country: "CN", code: "+86", flag: "🇨🇳", isoCode: "CN" },
  { country: "CO", code: "+57", flag: "🇨🇴", isoCode: "CO" },
  { country: "KM", code: "+269", flag: "🇰🇲", isoCode: "KM" },
  { country: "CG", code: "+242", flag: "🇨🇬", isoCode: "CG" },
  { country: "CR", code: "+506", flag: "🇨🇷", isoCode: "CR" },
  { country: "CI", code: "+225", flag: "🇨🇮", isoCode: "CI" },
  { country: "HR", code: "+385", flag: "🇭🇷", isoCode: "HR" },
  { country: "CU", code: "+53", flag: "🇨🇺", isoCode: "CU" },
  { country: "CY", code: "+357", flag: "🇨🇾", isoCode: "CY" },
  { country: "CZ", code: "+420", flag: "🇨🇿", isoCode: "CZ" },
  { country: "DK", code: "+45", flag: "🇩🇰", isoCode: "DK" },
  { country: "DJ", code: "+253", flag: "🇩🇯", isoCode: "DJ" },
  { country: "DM", code: "+1767", flag: "🇩🇲", isoCode: "DM" },
  { country: "DO", code: "+1809", flag: "🇩🇴", isoCode: "DO" },
  { country: "EC", code: "+593", flag: "🇪🇨", isoCode: "EC" },
  { country: "EG", code: "+20", flag: "🇪🇬", isoCode: "EG" },
  { country: "SV", code: "+503", flag: "🇸🇻", isoCode: "SV" },
  { country: "GQ", code: "+240", flag: "🇬🇶", isoCode: "GQ" },
  { country: "ER", code: "+291", flag: "🇪🇷", isoCode: "ER" },
  { country: "EE", code: "+372", flag: "🇪🇪", isoCode: "EE" },
  { country: "SZ", code: "+268", flag: "🇸🇿", isoCode: "SZ" },
  { country: "ET", code: "+251", flag: "🇪🇹", isoCode: "ET" },
  { country: "FJ", code: "+679", flag: "🇫🇯", isoCode: "FJ" },
  { country: "FI", code: "+358", flag: "🇫🇮", isoCode: "FI" },
  { country: "FR", code: "+33", flag: "🇫🇷", isoCode: "FR" },
  { country: "GA", code: "+241", flag: "🇬🇦", isoCode: "GA" },
  { country: "GM", code: "+220", flag: "🇬🇲", isoCode: "GM" },
  { country: "GE", code: "+995", flag: "🇬🇪", isoCode: "GE" },
  { country: "DE", code: "+49", flag: "🇩🇪", isoCode: "DE" },
  { country: "GH", code: "+233", flag: "🇬🇭", isoCode: "GH" },
  { country: "GR", code: "+30", flag: "🇬🇷", isoCode: "GR" },
  { country: "GD", code: "+1473", flag: "🇬🇩", isoCode: "GD" },
  { country: "GT", code: "+502", flag: "🇬🇹", isoCode: "GT" },
  { country: "GN", code: "+224", flag: "🇬🇳", isoCode: "GN" },
  { country: "GW", code: "+245", flag: "🇬🇼", isoCode: "GW" },
  { country: "GY", code: "+592", flag: "🇬🇾", isoCode: "GY" },
  { country: "HT", code: "+509", flag: "🇭🇹", isoCode: "HT" },
  { country: "HN", code: "+504", flag: "🇭🇳", isoCode: "HN" },
  { country: "HK", code: "+852", flag: "🇭🇰", isoCode: "HK" },
  { country: "HU", code: "+36", flag: "🇭🇺", isoCode: "HU" },
  { country: "IS", code: "+354", flag: "🇮🇸", isoCode: "IS" },
  { country: "IN", code: "+91", flag: "🇮🇳", isoCode: "IN" },
  { country: "ID", code: "+62", flag: "🇮🇩", isoCode: "ID" },
  { country: "IR", code: "+98", flag: "🇮🇷", isoCode: "IR" },
  { country: "IQ", code: "+964", flag: "🇮🇶", isoCode: "IQ" },
  { country: "IE", code: "+353", flag: "🇮🇪", isoCode: "IE" },
  { country: "IL", code: "+972", flag: "🇮🇱", isoCode: "IL" },
  { country: "IT", code: "+39", flag: "🇮🇹", isoCode: "IT" },
  { country: "JM", code: "+1876", flag: "🇯🇲", isoCode: "JM" },
  { country: "JP", code: "+81", flag: "🇯🇵", isoCode: "JP" },
  { country: "JO", code: "+962", flag: "🇯🇴", isoCode: "JO" },
  { country: "KZ", code: "+7", flag: "🇰🇿", isoCode: "KZ" },
  { country: "KE", code: "+254", flag: "🇰🇪", isoCode: "KE" },
  { country: "KI", code: "+686", flag: "🇰🇮", isoCode: "KI" },
  { country: "KP", code: "+850", flag: "🇰🇵", isoCode: "KP" },
  { country: "KR", code: "+82", flag: "🇰🇷", isoCode: "KR" },
  { country: "KW", code: "+965", flag: "🇰🇼", isoCode: "KW" },
  { country: "KG", code: "+996", flag: "🇰🇬", isoCode: "KG" },
  { country: "LA", code: "+856", flag: "🇱🇦", isoCode: "LA" },
  { country: "LV", code: "+371", flag: "🇱🇻", isoCode: "LV" },
  { country: "LB", code: "+961", flag: "🇱🇧", isoCode: "LB" },
  { country: "LS", code: "+266", flag: "🇱🇸", isoCode: "LS" },
  { country: "LR", code: "+231", flag: "🇱🇷", isoCode: "LR" },
  { country: "LY", code: "+218", flag: "🇱🇾", isoCode: "LY" },
  { country: "LI", code: "+423", flag: "🇱🇮", isoCode: "LI" },
  { country: "LT", code: "+370", flag: "🇱🇹", isoCode: "LT" },
  { country: "LU", code: "+352", flag: "🇱🇺", isoCode: "LU" },
  { country: "MO", code: "+853", flag: "🇲🇴", isoCode: "MO" },
  { country: "MG", code: "+261", flag: "🇲🇬", isoCode: "MG" },
  { country: "MW", code: "+265", flag: "🇲🇼", isoCode: "MW" },
  { country: "MY", code: "+60", flag: "🇲🇾", isoCode: "MY" },
  { country: "MV", code: "+960", flag: "🇲🇻", isoCode: "MV" },
  { country: "ML", code: "+223", flag: "🇲🇱", isoCode: "ML" },
  { country: "MT", code: "+356", flag: "🇲🇹", isoCode: "MT" },
  { country: "MH", code: "+692", flag: "🇲🇭", isoCode: "MH" },
  { country: "MR", code: "+222", flag: "🇲🇷", isoCode: "MR" },
  { country: "MU", code: "+230", flag: "🇲🇺", isoCode: "MU" },
  { country: "MX", code: "+52", flag: "🇲🇽", isoCode: "MX" },
  { country: "FM", code: "+691", flag: "🇫🇲", isoCode: "FM" },
  { country: "MD", code: "+373", flag: "🇲🇩", isoCode: "MD" },
  { country: "MC", code: "+377", flag: "🇲🇨", isoCode: "MC" },
  { country: "MN", code: "+976", flag: "🇲🇳", isoCode: "MN" },
  { country: "ME", code: "+382", flag: "🇲🇪", isoCode: "ME" },
  { country: "MA", code: "+212", flag: "🇲🇦", isoCode: "MA" },
  { country: "MZ", code: "+258", flag: "🇲🇿", isoCode: "MZ" },
  { country: "MM", code: "+95", flag: "🇲🇲", isoCode: "MM" },
  { country: "NA", code: "+264", flag: "🇳🇦", isoCode: "NA" },
  { country: "NR", code: "+674", flag: "🇳🇷", isoCode: "NR" },
  { country: "NP", code: "+977", flag: "🇳🇵", isoCode: "NP" },
  { country: "NL", code: "+31", flag: "🇳🇱", isoCode: "NL" },
  { country: "NZ", code: "+64", flag: "🇳🇿", isoCode: "NZ" },
  { country: "NI", code: "+505", flag: "🇳🇮", isoCode: "NI" },
  { country: "NE", code: "+227", flag: "🇳🇪", isoCode: "NE" },
  { country: "NG", code: "+234", flag: "🇳🇬", isoCode: "NG" },
  { country: "NO", code: "+47", flag: "🇳🇴", isoCode: "NO" },
  { country: "OM", code: "+968", flag: "🇴🇲", isoCode: "OM" },
  { country: "PK", code: "+92", flag: "🇵🇰", isoCode: "PK" },
  { country: "PW", code: "+680", flag: "🇵🇼", isoCode: "PW" },
  { country: "PA", code: "+507", flag: "🇵🇦", isoCode: "PA" },
  { country: "PG", code: "+675", flag: "🇵🇬", isoCode: "PG" },
  { country: "PY", code: "+595", flag: "🇵🇾", isoCode: "PY" },
  { country: "PE", code: "+51", flag: "🇵🇪", isoCode: "PE" },
  { country: "PH", code: "+63", flag: "🇵🇭", isoCode: "PH" },
  { country: "PL", code: "+48", flag: "🇵🇱", isoCode: "PL" },
  { country: "PT", code: "+351", flag: "🇵🇹", isoCode: "PT" },
  { country: "QA", code: "+974", flag: "🇶🇦", isoCode: "QA" },
  { country: "RO", code: "+40", flag: "🇷🇴", isoCode: "RO" },
  { country: "RU", code: "+7", flag: "🇷🇺", isoCode: "RU" },
  { country: "RW", code: "+250", flag: "🇷🇼", isoCode: "RW" },
  { country: "KN", code: "+1869", flag: "🇰🇳", isoCode: "KN" },
  { country: "LC", code: "+1758", flag: "🇱🇨", isoCode: "LC" },
  { country: "VC", code: "+1784", flag: "🇻🇨", isoCode: "VC" },
  { country: "WS", code: "+685", flag: "🇼🇸", isoCode: "WS" },
  { country: "SM", code: "+378", flag: "🇸🇲", isoCode: "SM" },
  { country: "ST", code: "+239", flag: "🇸🇹", isoCode: "ST" },
  { country: "SA", code: "+966", flag: "🇸🇦", isoCode: "SA" },
  { country: "SN", code: "+221", flag: "🇸🇳", isoCode: "SN" },
  { country: "RS", code: "+381", flag: "🇷🇸", isoCode: "RS" },
  { country: "SC", code: "+248", flag: "🇸🇨", isoCode: "SC" },
  { country: "SL", code: "+232", flag: "🇸🇱", isoCode: "SL" },
  { country: "SG", code: "+65", flag: "🇸🇬", isoCode: "SG" },
  { country: "SK", code: "+421", flag: "🇸🇰", isoCode: "SK" },
  { country: "SI", code: "+386", flag: "🇸🇮", isoCode: "SI" },
  { country: "SB", code: "+677", flag: "🇸🇧", isoCode: "SB" },
  { country: "SO", code: "+252", flag: "🇸🇴", isoCode: "SO" },
  { country: "ZA", code: "+27", flag: "🇿🇦", isoCode: "ZA" },
  { country: "ES", code: "+34", flag: "🇪🇸", isoCode: "ES" },
  { country: "LK", code: "+94", flag: "🇱🇰", isoCode: "LK" },
  { country: "SD", code: "+249", flag: "🇸🇩", isoCode: "SD" },
  { country: "SR", code: "+597", flag: "🇸🇷", isoCode: "SR" },
  { country: "SE", code: "+46", flag: "🇸🇪", isoCode: "SE" },
  { country: "CH", code: "+41", flag: "🇨🇭", isoCode: "CH" },
  { country: "SY", code: "+963", flag: "🇸🇾", isoCode: "SY" },
  { country: "TW", code: "+886", flag: "🇹🇼", isoCode: "TW" },
  { country: "TJ", code: "+992", flag: "🇹🇯", isoCode: "TJ" },
  { country: "TZ", code: "+255", flag: "🇹🇿", isoCode: "TZ" },
  { country: "TH", code: "+66", flag: "🇹🇭", isoCode: "TH" },
  { country: "TL", code: "+670", flag: "🇹🇱", isoCode: "TL" },
  { country: "TG", code: "+228", flag: "🇹🇬", isoCode: "TG" },
  { country: "TO", code: "+676", flag: "🇹🇴", isoCode: "TO" },
  { country: "TT", code: "+1868", flag: "🇹🇹", isoCode: "TT" },
  { country: "TN", code: "+216", flag: "🇹🇳", isoCode: "TN" },
  { country: "TR", code: "+90", flag: "🇹🇷", isoCode: "TR" },
  { country: "TM", code: "+993", flag: "🇹🇲", isoCode: "TM" },
  { country: "TV", code: "+688", flag: "🇹🇻", isoCode: "TV" },
  { country: "UG", code: "+256", flag: "🇺🇬", isoCode: "UG" },
  { country: "UA", code: "+380", flag: "🇺🇦", isoCode: "UA" },
  { country: "AE", code: "+971", flag: "🇦🇪", isoCode: "AE" },
  { country: "GB", code: "+44", flag: "🇬🇧", isoCode: "GB" },
  { country: "US", code: "+1", flag: "🇺🇸", isoCode: "US" },
  { country: "UY", code: "+598", flag: "🇺🇾", isoCode: "UY" },
  { country: "UZ", code: "+998", flag: "🇺🇿", isoCode: "UZ" },
  { country: "VU", code: "+678", flag: "🇻🇺", isoCode: "VU" },
  { country: "VE", code: "+58", flag: "🇻🇪", isoCode: "VE" },
  { country: "VN", code: "+84", flag: "🇻🇳", isoCode: "VN" },
  { country: "YE", code: "+967", flag: "🇾🇪", isoCode: "YE" },
  { country: "ZM", code: "+260", flag: "🇿🇲", isoCode: "ZM" },
  { country: "ZW", code: "+263", flag: "🇿🇼", isoCode: "ZW" },
];

export default CountryCodes;
