import React from "react";
import { IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useUser } from "../../store/UserContext";

const UserAvatarButton = ({ handleAvatarMenu }) => {
  const { userInfo } = useUser();

  return (
    <>
      <IconButton
        edge="end"
        color="inherit"
        aria-label={userInfo.avatar ? "user-avatar" : "settings"}
        onClick={handleAvatarMenu}
      >
        {userInfo.avatar ? (
          <img
            src={userInfo.avatar}
            alt="User Avatar"
            style={{ width: 30, height: 30, borderRadius: "50%" }}
          />
        ) : (
          <SettingsIcon />
        )}
      </IconButton>
    </>
  );
};

export default UserAvatarButton;
