import React from "react";
import { Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../store/Store";
import { useUser } from "../../store/UserContext";

const UserLogoutMenu = ({ anchorEl, handleClose }) => {
  const dispatch = useDispatch();
  const { userInfo, logout } = useUser();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      // if (isTokenExists()) {
      //   const response = await logoutGoogle();
      //   if (response.status !== 200) {
      //     console.error("Logout failed:", response);
      //   }
      // }
    } catch (error) {
      console.error("Error during logout:", error);
    } finally {
      logout();
      handleClose();
      dispatch(setCurrentPage("/"));
      navigate("/"); // Redirect to login page or home
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl) && Boolean(userInfo.name)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );
};

export default UserLogoutMenu;
