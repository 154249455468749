import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { UseApi } from "../../../api/UseApi";

const accordionItems = [
  "Admin Summary",
  "Charts",
  "Config",
  "Checkpoints",
  "Data",
  "Leaderboard",
  "Progress",
  "Reports",
  "Updates",
];

const ResearchAccordion = () => {
  const [expandedItems, setExpandedItems] = useState([]);
  const [metricsData, setMetricsData] = useState(null);
  const { getWithAuth } = UseApi();

  const toggleAccordion = (item) => {
    setExpandedItems((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item],
    );
  };

  const collapseAll = () => {
    setExpandedItems([]);
  };

  const refreshMetrics = async () => {
    try {
      const response = await getWithAuth("/api/research/refresh", "internal");
      if (response) {
        setMetricsData(response.data);
      }
    } catch (error) {
      console.error("Failed to refresh metrics:", error);
    }
  };

  return (
    <div>
      {accordionItems.map((item) => (
        <div
          key={item}
          style={{
            backgroundColor: "#3b82f6",
            color: "white",
            margin: "5px 0",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <div
            onClick={() => toggleAccordion(item)}
            style={{ cursor: "pointer" }}
          >
            {item}
          </div>
          {expandedItems.includes(item) && (
            <div
              style={{
                padding: "10px",
                backgroundColor: "#1e3a8a",
                borderRadius: "5px",
              }}
            >
              {item === "Admin Summary" && (
                <div>
                  <button
                    onClick={refreshMetrics}
                    style={{
                      padding: "8px 16px",
                      backgroundColor: "#4caf50",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      marginBottom: "10px",
                    }}
                  >
                    Refresh Metrics
                  </button>
                  {metricsData && (
                    <pre
                      style={{
                        backgroundColor: "#f3f4f6",
                        padding: "10px",
                        borderRadius: "5px",
                        maxHeight: "400px",
                        overflowY: "auto",
                        color: "#333",
                      }}
                    >
                      {JSON.stringify(metricsData, null, 2)}
                    </pre>
                  )}
                </div>
              )}
              Content for {item}
            </div>
          )}
        </div>
      ))}
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          cursor: "pointer",
          backgroundColor: "#93c5fd",
          borderRadius: "50%",
          padding: "10px",
        }}
        onClick={collapseAll}
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </div>
    </div>
  );
};

export default ResearchAccordion;
