import React, { useState, useEffect } from "react";
import CurrencyInput from "react-currency-input-field";
import { upsertAnswer } from "../../util/Util";

const WaistPicker = ({
  waist,
  setWaist,
  surveyResponse,
  setSurveyResponse,
}) => {
  const [inputClass, setInputClass] = useState("input-unfocused");
  const handleWaistChange = (value) => {
    if (typeof value === "undefined" || value === null || value === "") {
      setWaist("");
      localStorage.setItem("userWaist", "");
      upsertAnswer(800, "", [], setSurveyResponse);
      return;
    }
    const parsedValue = parseInt(value);
    if (!isNaN(parsedValue) && parsedValue < 100) {
      setWaist(parsedValue);
      localStorage.setItem("userWaist", parsedValue.toString());
      upsertAnswer(800, parsedValue.toString(), [], setSurveyResponse);
    }
  };
  useEffect(() => {
    const storedWaist = localStorage.getItem("userWaist");
    upsertAnswer(800, storedWaist, [], setSurveyResponse);
  }, [setWaist, setSurveyResponse]);

  return (
    <div className="flex justify-center items-center">
      <CurrencyInput
        className={`text-center ${inputClass}`}
        style={{
          fontSize: "3rem",
          width: "55vw",
          height: "4rem",
        }}
        value={waist}
        decimalsLimit={0}
        onValueChange={handleWaistChange}
        allowNegativeValue={false}
        disableGroupSeparators={true}
        placeholder="0"
        onFocus={() => setInputClass("input-focused")}
        onBlur={() => setInputClass("input-unfocused")}
      />
      <span className="ml-2" style={{ fontSize: "3rem" }}>
        inch
      </span>
    </div>
  );
};

export default WaistPicker;
