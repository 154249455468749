import { useEffect, useState } from "react";
import Typewriter from "typewriter-effect";
import { useUser } from "../../store/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVenus, faMars } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import CountryCodes from "../../util/CountryCodes";
import WeightPicker from "./WeightPicker";
import HeightPicker from "./HeightPicker";
import WaistPicker from "./WaistPicker";
import LifestyleChanges from "./LifestyleChanges";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../store/Store";
import { upsertAnswer } from "../../util/Util";
import AIPicker from "./AIPicker";
import { UseApi } from "../../api/UseApi";
import MyEnv from "../../util/MyEnv";

const SurveyQuestions = () => {
  const { getWithAuth } = UseApi();
  const { postWithAuth } = UseApi();
  const { userInfo } = useUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [weight, setWeight] = useState(58.0);
  const [height, setHeight] = useState(170);
  const [waist, setWaist] = useState(32);
  const [currentQuestion, setCurrentQuestion] = useState(() => {
    return parseInt(localStorage.getItem("currentQuestion")) || 100;
  });

  const [surveyResponse, setSurveyResponse] = useState(() => {
    const savedResponse = localStorage.getItem("surveyResponse");
    return savedResponse ? JSON.parse(savedResponse) : { survey_data: [] };
  });
  const [isSurveyLoaded, setIsSurveyLoaded] = useState(() => {
    // Check if the survey is marked as loaded in localStorage
    return localStorage.getItem("isSurveyLoaded") === "true";
  });

  const selectedGender =
    surveyResponse?.survey_data?.find((answer) => answer.questionNumber === 200)
      ?.answerString || "";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState({
    country: "Malaysia",
    code: "+60",
    flag: "🇲🇾",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCodeSearch, setCountryCodeSearch] = useState("");
  const [isTouched, setIsTouched] = useState(false);
  const [selectedAgeGroup, setSelectedAgeGroup] = useState("");
  const [selectedEthnicity, setSelectedEthnicity] = useState("");

  const completeSurvey = async () => {
    try {
      saveUpsert();
    } catch (err) {
      debugger;
      console.log(err);
    }
    dispatch(setCurrentPage("/daily"));
    navigate("/daily");
  };

  const nickname =
    surveyResponse.survey_data.find((q) => q.questionNumber === 100)
      ?.answerString || "";

  useEffect(() => {
    const savedAgeGroup =
      surveyResponse.survey_data.find((q) => q.questionNumber === 300)
        ?.answerString || "";
    setSelectedAgeGroup(savedAgeGroup);
  }, [surveyResponse]);

  useEffect(() => {
    const savedEthnicity =
      surveyResponse.survey_data.find((q) => q.questionNumber === 400)
        ?.answerString || "";
    setSelectedEthnicity(savedEthnicity);
  }, [surveyResponse]);

  useEffect(() => {
    // Retrieve the saved phone number from surveyResponse
    const savedPhoneNumber =
      surveyResponse.survey_data.find((q) => q.questionNumber === 500)
        ?.answerString || "";
    const savedCountryCode =
      surveyResponse.survey_data.find((q) => q.questionNumber === 501)
        ?.answerString || "";
    setPhoneNumber(savedPhoneNumber);
    const countryCodeObject = CountryCodes.find(
      (country) => country.code === savedCountryCode,
    );

    if (countryCodeObject) {
      setSelectedCountryCode(countryCodeObject);
    }
  }, [surveyResponse]);

  useEffect(() => {
    localStorage.setItem("currentQuestion", currentQuestion);
    localStorage.setItem("surveyResponse", JSON.stringify(surveyResponse));
  }, [currentQuestion, surveyResponse]);

  useEffect(() => {
    if (!isSurveyLoaded) {
      const loadSurvey = async () => {
        try {
          const response = await getWithAuth(MyEnv.S_LOAD, "internal");
          if (response && response.data) {
            const { total, entry } = response.data;
            if (total > 0 && entry.length > 0) {
              setSurveyResponse(entry[0].resource);
            } else {
              console.log("No survey data found.");
              setSurveyResponse({ survey_data: [] });
            }
          }
          localStorage.setItem("isSurveyLoaded", "true");
          setIsSurveyLoaded(true);
        } catch (err) {
          console.error("Failed to load survey:", err);
        }
      };

      loadSurvey();
    }
  }, [getWithAuth, isSurveyLoaded]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z0-9 ]*$/.test(value)) {
      upsertAnswer(currentQuestion, value, [], setSurveyResponse);
    }
  };
  const handleNickNameKeyDown = (event) => {
    if (event.key === "Enter" && nickname !== "") {
      saveUpsert();
      setCurrentQuestion(200);
    }
  };

  const saveUpsert = async (defineSurveyData = null) => {
    try {
      await postWithAuth(
        "/api/survey/upsert",
        "internal",
        defineSurveyData !== null
          ? defineSurveyData
          : surveyResponse?.survey_data,
      );
    } catch (err) {
      console.error("Failed to upsert:", err);
    }
  };

  const handleBlur = () => {
    setIsTouched(true);
  };

  const handleBack = () => {
    if (currentQuestion > 100) {
      setCurrentQuestion(currentQuestion - 100);
    }
  };

  const handleAgeGroupSelect = (ageGroup) => {
    setSelectedAgeGroup(ageGroup);
    upsertAnswer(300, ageGroup, [], setSurveyResponse);
    setIsModalOpen(false);
    saveUpsert();
    setCurrentQuestion(400);
  };

  const handleEthnicitySelect = (ethnicity) => {
    setSelectedEthnicity(ethnicity);
    upsertAnswer(400, ethnicity, [], setSurveyResponse);
    setIsModalOpen(false);
    saveUpsert();
    setCurrentQuestion(500);
  };

  const handlePhoneNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.startsWith("06") || value.startsWith("60")) {
      value = value.slice(2);
    }
    if (value.startsWith("0") || value.startsWith("6")) {
      value = value.slice(1);
    }
    if (value.length <= 20) {
      setPhoneNumber(value);
    }
  };

  const handlePhoneNumberKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSavePhoneNumber();
    }
  };

  const handleSavePhoneNumber = () => {
    upsertAnswer(500, phoneNumber, [], setSurveyResponse);
    upsertAnswer(501, selectedCountryCode.code, [], setSurveyResponse);
    saveUpsert();
    setCurrentQuestion(600);
  };

  const handleCountryCodeSelect = (country) => {
    setSelectedCountryCode(country);
    upsertAnswer(501, selectedCountryCode.code, [], setSurveyResponse);
    saveUpsert();
    setIsModalOpen(false);
    setCountryCodeSearch("");
  };

  const filteredCountryCodes = CountryCodes.filter(
    (country) =>
      country.country.toLowerCase().includes(countryCodeSearch.toLowerCase()) ||
      country.code.includes(countryCodeSearch),
  ).sort((a, b) => a.isoCode.localeCompare(b.isoCode));

  return (
    <>
      <div className="flex justify-center mt-4"></div>
      {currentQuestion === 100 && (
        <>
          <div className="flex justify-center mt-4">
            <img
              src="chat2/05-cute-chatbot.jpg"
              alt="healthChat"
              className="w-16 h-16 rounded-full object-cover"
            />
            <div className="text-center text-xl font-thin text-blue-600">
              <span>Let's start the survey, </span>
              <br />
              <span>{userInfo.name}.</span>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <div className="text-center text-xl font-thin text-blue-600">
              {nickname && <span>What's your nickname?</span>}
              {!nickname && (
                <Typewriter
                  options={{
                    strings: [`What's your nickname?`],
                    autoStart: true,
                    loop: false,
                    deleteSpeed: Infinity,
                    cursor: "",
                  }}
                />
              )}
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <div className="flex flex-col items-center mt-2">
              <input
                type="text"
                value={nickname}
                onChange={handleInputChange}
                onBlur={handleBlur}
                onKeyDown={handleNickNameKeyDown}
                placeholder="Enter your nickname"
                className="border p-2 rounded"
                maxLength={15}
              />
              {isTouched && nickname === "" && (
                <span className="text-red-500 mt-2">
                  Nickname must not be empty
                </span>
              )}
              <button
                type="button"
                onClick={() => {
                  if (nickname !== "") {
                    saveUpsert();
                    setCurrentQuestion(200);
                  }
                }}
                className={`mt-2 px-4 py-2 text-white rounded ${
                  nickname === ""
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-blue-500"
                }`}
                disabled={nickname === ""}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
      {currentQuestion === 200 && (
        <>
          <div className="flex justify-center mt-1">
            <img
              src="chat2/05-cute-chatbot.jpg"
              alt="healthChat"
              className="w-16 h-16 rounded-full object-cover"
            />
          </div>
          <div className="flex justify-center mt-4">
            <div className="text-center text-xl font-thin text-blue-600">
              {selectedGender && <span>Your gender, {nickname}</span>}
              {!selectedGender && (
                <Typewriter
                  options={{
                    strings: [`Your gender, ${nickname}?`],
                    autoStart: true,
                    loop: false,
                    deleteSpeed: Infinity,
                    cursor: "",
                  }}
                />
              )}
            </div>
          </div>
          <div className="flex justify-center mt-2">
            <button
              type="button"
              onClick={() => {
                upsertAnswer(200, "female", [], setSurveyResponse);
                saveUpsert();
                setCurrentQuestion(300);
              }}
              className={`px-5 py-2 rounded mr-5 border-2 ${
                selectedGender === "female"
                  ? "border-pink-500 text-pink-500 bg-white"
                  : "bg-pink-500 text-white"
              }`}
            >
              <FontAwesomeIcon icon={faVenus} size="2x" />
            </button>
            <button
              type="button"
              onClick={() => {
                upsertAnswer(200, "male", [], setSurveyResponse);
                saveUpsert();
                setCurrentQuestion(300);
              }}
              className={`px-4 py-2 rounded border-2 ${
                selectedGender === "male"
                  ? "border-blue-500 text-blue-500 bg-white"
                  : "bg-blue-500 text-white"
              }`}
            >
              <FontAwesomeIcon icon={faMars} size="2x" />
            </button>
          </div>
          <div className="flex justify-center mt-2">
            <button
              type="button"
              onClick={handleBack}
              className={`mt-2 px-4 py-2 bg-gray-500 text-white rounded ${
                selectedGender ? "mr-5" : ""
              }`}
            >
              Back
            </button>
            {selectedGender && (
              <>
                <button
                  type="button"
                  onClick={() => setCurrentQuestion(300)}
                  className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
                >
                  Next
                </button>
              </>
            )}
          </div>
        </>
      )}

      {currentQuestion === 300 && (
        <>
          <div className="flex justify-center mt-1">
            <img
              src="chat2/05-cute-chatbot.jpg"
              alt="healthChat"
              className="w-16 h-16 rounded-full object-cover"
            />
          </div>
          <div className="flex justify-center mt-4">
            <div className="text-center text-xl font-thin text-blue-600">
              {selectedAgeGroup && <span>How many 🎂birthdays you had?</span>}
              {!selectedAgeGroup && (
                <Typewriter
                  options={{
                    strings: [`How many 🎂birthdays you had?`],
                    autoStart: true,
                    loop: false,
                    deleteSpeed: Infinity,
                    cursor: "",
                  }}
                />
              )}
            </div>
          </div>
          <div className="flex justify-center mt-2">
            <button
              type="button"
              onClick={() => setIsModalOpen(true)}
              className={`px-4 py-2 rounded ${
                selectedAgeGroup
                  ? "border border-blue-500 bg-white text-blue-500"
                  : "bg-blue-500 text-white"
              }`}
            >
              {selectedAgeGroup || "Select Age Group"}
            </button>
          </div>
          {selectedAgeGroup && (
            <div className="flex justify-center mt-2">
              <button
                type="button"
                onClick={handleBack}
                className="mt-2 px-4 py-2 bg-gray-500 text-white rounded mr-3"
              >
                Back
              </button>
              <button
                type="button"
                onClick={() => setCurrentQuestion(400)}
                className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
              >
                Next
              </button>
            </div>
          )}
          {!selectedAgeGroup && (
            <div className="flex justify-center mt-2">
              <button
                type="button"
                onClick={handleBack}
                className="mt-2 px-4 py-2 bg-gray-500 text-white rounded"
              >
                Back
              </button>
            </div>
          )}
        </>
      )}

      {currentQuestion === 400 && (
        <>
          <div className="flex justify-center mt-1">
            <img
              src="chat2/05-cute-chatbot.jpg"
              alt="healthChat"
              className="w-16 h-16 rounded-full object-cover"
            />
          </div>
          <div className="flex justify-center mt-4">
            <div className="text-center text-xl font-thin text-blue-600">
              {selectedEthnicity && (
                <span>{nickname}, you're considered as?</span>
              )}
              {!selectedEthnicity && (
                <Typewriter
                  options={{
                    strings: [`${nickname}, you're considered as?`],
                    autoStart: true,
                    loop: false,
                    deleteSpeed: Infinity,
                    cursor: "",
                  }}
                />
              )}
            </div>
          </div>
          <div className="flex justify-center mt-2">
            <button
              type="button"
              onClick={() => setIsModalOpen(true)}
              className={`mt-2 px-4 py-2 rounded border-2 ${
                selectedEthnicity
                  ? "border border-blue-500 bg-white text-blue-500"
                  : "bg-blue-500 text-white"
              }`}
            >
              {selectedEthnicity && <span>{selectedEthnicity}</span>}
              {!selectedEthnicity && <span>Select Ethnicity</span>}
            </button>
          </div>
          <div className="flex justify-center mt-2">
            <button
              type="button"
              onClick={handleBack}
              className={`mt-2 px-4 py-2 bg-gray-500 text-white rounded ${
                selectedEthnicity ? "mr-3" : ""
              }`}
            >
              Back
            </button>
            {selectedEthnicity && (
              <button
                type="button"
                onClick={() => setCurrentQuestion(500)}
                className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
              >
                Next
              </button>
            )}
          </div>
        </>
      )}
      {currentQuestion === 500 && (
        <>
          <div className="flex justify-center mt-1">
            <img
              src="chat2/05-cute-chatbot.jpg"
              alt="healthChat"
              className="w-16 h-16 rounded-full object-cover"
            />
          </div>
          <div className="flex justify-center mt-4">
            <div className="text-center text-xl font-thin text-blue-600">
              {phoneNumber && (
                <span>
                  {nickname}, can I ring you💬?
                  <br />
                  (for research only)
                </span>
              )}
              {!phoneNumber && (
                <Typewriter
                  options={{
                    strings: [
                      `${nickname}, can I ring you💬?<br/>(for research only)`,
                    ],
                    autoStart: true,
                    loop: false,
                    deleteSpeed: Infinity,
                    cursor: "",
                  }}
                />
              )}
            </div>
          </div>
          <div className="flex justify-center mt-2">
            <div className="flex items-center border rounded">
              <button type="button" className="flex items-center px-3">
                {/*onClick={() => setIsModalOpen(true)}*/}
                <span className="mr-2">{selectedCountryCode.flag}</span>
                <span>{selectedCountryCode.code}</span>
                <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
              </button>
              <input
                type="text"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                onKeyDown={handlePhoneNumberKeyDown}
                placeholder="Your phone"
                className="p-2 rounded border-l"
                maxLength={20}
              />
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <button
              type="button"
              onClick={handleBack}
              className="mt-2 px-4 py-2 bg-gray-500 text-white rounded mr-3"
            >
              Back
            </button>
            <button
              type="button"
              onClick={handleSavePhoneNumber}
              className={`mt-2 px-4 py-2 bg-blue-500 text-white rounded ${
                phoneNumber === ""
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-blue-500"
              }`}
              disabled={phoneNumber === ""}
            >
              Next
            </button>
          </div>
        </>
      )}

      {currentQuestion === 600 && (
        <>
          <div className="flex justify-center mt-1">
            <img
              src="chat2/05-cute-chatbot.jpg"
              alt="healthChat"
              className="w-16 h-16 rounded-full object-cover"
            />
          </div>
          <div className="flex justify-center mt-4">
            <div className="text-center text-xl font-thin text-blue-600">
              {weight && (
                <>
                  {nickname}, to understand
                  <br /> your overall health,
                  <br />
                  mind sharing your weight🏋️‍♀️?
                </>
              )}
              {!weight && (
                <Typewriter
                  options={{
                    strings: [
                      `${nickname}, to understand<br/> your overall health,<br/>mind sharing your weight🏋️‍♀️?`,
                    ],
                    autoStart: true,
                    loop: false,
                    deleteSpeed: Infinity,
                    cursor: "",
                  }}
                />
              )}
            </div>
          </div>
          <div className="flex justify-center mt-2">
            <WeightPicker
              weight={weight}
              setWeight={setWeight}
              surveyResponse={surveyResponse}
              setSurveyResponse={setSurveyResponse}
            />
          </div>
          <div className="flex justify-center mt-2">
            <button
              type="button"
              onClick={handleBack}
              className="mt-2 px-4 py-2 bg-gray-500 text-white rounded mr-2"
            >
              Back
            </button>
            <button
              type="button"
              onClick={() => {
                saveUpsert();
                setCurrentQuestion(700);
              }}
              className={`mt-2 px-4 py-2 bg-blue-500 text-white rounded ${
                weight === "" || weight < 10
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-blue-500"
              }`}
              disabled={weight === "" || weight < 10}
            >
              Next
            </button>
          </div>
        </>
      )}

      {currentQuestion === 700 && (
        <>
          <div className="flex justify-center mt-1">
            <img
              src="chat2/05-cute-chatbot.jpg"
              alt="healthChat"
              className="w-16 h-16 rounded-full object-cover"
            />
          </div>
          <div className="flex justify-center mt-4">
            <div className="text-center text-xl font-thin text-blue-600">
              {height && (
                <>
                  {nickname},<br />
                  just to confirm, <br />
                  how tall are you?
                </>
              )}
              {!height && (
                <Typewriter
                  options={{
                    strings: [
                      `${nickname},<br/>just to confirm, <br/>how tall are you?`,
                    ],
                    autoStart: true,
                    loop: false,
                    deleteSpeed: Infinity,
                    cursor: "",
                  }}
                />
              )}
            </div>
          </div>
          <div className="flex justify-center mt-2">
            <HeightPicker
              height={height}
              setHeight={setHeight}
              surveyResponse={surveyResponse}
              setSurveyResponse={setSurveyResponse}
            />
          </div>
          <div className="flex justify-center mt-2">
            <button
              type="button"
              onClick={handleBack}
              className="mt-2 px-4 py-2 bg-gray-500 text-white rounded mr-2"
            >
              Back
            </button>
            <button
              type="button"
              onClick={() => {
                if (height === "" || height < 80) {
                  return;
                }
                localStorage.setItem("userHeight", height.toString());
                upsertAnswer(700, height.toString(), [], setSurveyResponse);
                saveUpsert();
                setCurrentQuestion(800);
              }}
              className={`mt-2 px-4 py-2 bg-blue-500 text-white rounded ${
                height === "" || height < 80
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-blue-500"
              }`}
              disabled={height === "" || height < 80}
            >
              Next
            </button>
          </div>
        </>
      )}
      {currentQuestion === 800 && (
        <>
          <div className="flex justify-center mt-1">
            <img
              src="chat2/05-cute-chatbot.jpg"
              alt="healthChat"
              className="w-16 h-16 rounded-full object-cover"
            />
          </div>
          <div className="flex justify-center mt-4">
            <div className="text-center text-xl font-thin text-blue-600">
              {waist && (
                <>
                  {nickname}, could you please tell me <br /> your waist
                  circumference,
                  <br /> measured at belly button?
                </>
              )}
              {!waist && (
                <Typewriter
                  options={{
                    strings: [
                      `${nickname}, could you please tell me <br/> your waist circumference,<br/> measured at belly button?`,
                    ],
                    autoStart: true,
                    loop: false,
                    deleteSpeed: Infinity,
                    cursor: "",
                  }}
                />
              )}
            </div>
          </div>
          <div className="flex justify-center mt-2">
            <WaistPicker
              waist={waist}
              setWaist={setWaist}
              surveyResponse={surveyResponse}
              setSurveyResponse={setSurveyResponse}
            />
          </div>
          <div className="flex justify-center mt-2">
            <button
              type="button"
              onClick={handleBack}
              className="mt-2 px-4 py-2 bg-gray-500 text-white rounded mr-2"
            >
              Back
            </button>
            <button
              type="button"
              onClick={() => {
                localStorage.setItem("userWaist", waist.toString());
                upsertAnswer(800, waist.toString(), [], setSurveyResponse);
                saveUpsert();
                setCurrentQuestion(900);
              }}
              className={`mt-2 px-4 py-2 bg-blue-500 text-white rounded ${
                waist === "" || waist < 10
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-blue-500"
              }`}
              disabled={waist === "" || waist < 10}
            >
              Next
            </button>
          </div>
        </>
      )}
      {currentQuestion === 900 && (
        <>
          <div className="flex justify-center mt-1">
            <img
              src="chat2/05-cute-chatbot.jpg"
              alt="healthChat"
              className="w-16 h-16 rounded-full object-cover"
            />
          </div>
          <LifestyleChanges
            setCurrentQuestion={setCurrentQuestion}
            saveUpsert={saveUpsert}
            surveyResponse={surveyResponse}
            setSurveyResponse={setSurveyResponse}
          />
        </>
      )}
      {currentQuestion === 1000 && (
        <>
          <AIPicker
            setCurrentQuestion={setCurrentQuestion}
            surveyResponse={surveyResponse}
            setSurveyResponse={setSurveyResponse}
            completeSurvey={completeSurvey}
            saveUpsert={saveUpsert}
            handleBack={handleBack}
          />
        </>
      )}

      {isModalOpen && currentQuestion === 300 && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Select Your Age Group</h2>
            <div className="flex flex-col space-y-2">
              <button
                onClick={() => handleAgeGroupSelect("≤20")}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                ≤20
              </button>
              <button
                onClick={() => handleAgeGroupSelect("21-30")}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                21-30
              </button>
              <button
                onClick={() => handleAgeGroupSelect("31-40")}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                31-40
              </button>
              <button
                onClick={() => handleAgeGroupSelect("41-50")}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                41-50
              </button>
              <button
                onClick={() => handleAgeGroupSelect("51-60")}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                51-60
              </button>
              <button
                onClick={() => handleAgeGroupSelect("≥61")}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                ≥61
              </button>
            </div>
            <button
              onClick={() => setIsModalOpen(false)}
              className="mt-4 bg-gray-500 text-white px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
      {isModalOpen && currentQuestion === 400 && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">Select Ethnicity</h2>
            <div className="flex flex-col space-y-2 max-h-64 overflow-y-auto">
              {[
                "Malay",
                "Chinese",
                "Indian",
                "Bumiputera Sabah",
                "Bumiputera Sarawak",
                "Other Bumiputera Sabah",
                "Other Bumiputera Sarawak",
                "Orang Asli",
                "Bajau",
                "Kadazan",
                "Kadazan Dusun",
                "Murut",
                "Malay Sarawak",
                "Melanau",
                "Kadayan",
                "Iban",
                "Bidayuh",
                "Others",
              ].map((ethnicity) => (
                <button
                  key={ethnicity}
                  onClick={() => handleEthnicitySelect(ethnicity)}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  {ethnicity}
                </button>
              ))}
            </div>
            <button
              onClick={() => setIsModalOpen(false)}
              className="mt-4 bg-gray-500 text-white px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {isModalOpen && currentQuestion === 500 && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">Select Country Code</h2>
            <input
              type="text"
              value={countryCodeSearch}
              onChange={(e) => setCountryCodeSearch(e.target.value)}
              placeholder="Search by country or code"
              className="mb-4 p-2 border rounded w-full"
            />
            <div
              className="grid gap-2 max-h-64 overflow-y-auto"
              style={{
                gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
              }}
            >
              {filteredCountryCodes.map((country) => (
                <button
                  key={country.isoCode}
                  onClick={() => handleCountryCodeSelect(country)}
                  className="flex justify-between items-center bg-blue-500 text-white px-4 py-2 rounded"
                >
                  <span>
                    {country.flag} {country.country}
                  </span>
                  <span className="text-sm">{country.code}</span>
                </button>
              ))}
            </div>
            <button
              onClick={() => setIsModalOpen(false)}
              className="mt-4 bg-gray-500 text-white px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SurveyQuestions;
