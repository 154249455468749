import React, { useState, useEffect } from "react";
import { calculateDailyCoins, readSurveyResponse } from "./io/DailyLogic";
import { UseApi } from "../../api/UseApi";

const DailyMission = () => {
  const [coins, setCoins] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const api = UseApi();

  useEffect(() => {
    const fetchSurveyResponse = async () => {
      try {
        setIsLoading(true);
        const response = readSurveyResponse();
        const surveyResponse = response?.survey_data;
        if (!localStorage.getItem("isSurveyComplete")) {
          localStorage.setItem("isSurveyComplete", 1);
          await api.postWithAuth(
            "/api/survey/upsert",
            "internal",
            surveyResponse,
          );

          const userInfoString = localStorage.getItem("userInfo");
          if (!userInfoString) {
            throw new Error("User info not found in localStorage");
          }
          const userInfo = JSON.parse(userInfoString);
          api.postWithAuth("/api/userprofile/convert/fromsurvey", "internal", {
            email: userInfo.email,
            name: userInfo.name,
          });
        }
        const earnedCoins = calculateDailyCoins(surveyResponse);
        setCoins(earnedCoins);
      } catch (error) {
        console.error("Error fetching survey response:", error);
        setCoins(0);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSurveyResponse();
  }, [api]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col justify-center items-center mt-4">
      <div className="text-center text-xl font-bold text-green-600">
        Welcome to Daily Mission
      </div>
      <div className="mt-2">You earned {coins} coins today.</div>
    </div>
  );
};

export default DailyMission;
