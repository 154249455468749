import React, { useState, useEffect } from "react";
import Ripples from "react-ripples";
import Typewriter from "typewriter-effect";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useUser } from "../store/UserContext";
import FloatingPanel from "./start/FloatingPanel";
import "../input.css";
import "./Home.css";

const Home = () => {
  const styles = {
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px",
    },
    logo: {
      width: "100px",
      height: "auto",
    },
  };

  const [showTypewriter, setShowTypewriter] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isFadingOut, setIsFadingOut] = useState(false);
  const { userInfo } = useUser();

  const currentPage = useSelector((state) => state.app.currentPage);

  useEffect(() => {
    const visited = localStorage.getItem("isVisited");
    const queryString = window.location.search; // Get the current query string

    if (currentPage === "/survey") {
      navigate("/survey");
    }

    const timer = setTimeout(() => {
      setShowTypewriter(true);
    }, 5000);
    if (visited === "1") {
    } else {
      localStorage.setItem("isVisited", "1");
      navigate(`/start${queryString}`); // Navigate to Start with query string
    }

    return () => clearTimeout(timer);
  }, [currentPage, navigate, dispatch]);

  const handleClick = () => {
    setIsFadingOut(true);

    setTimeout(() => {
      navigate("/testchat");
    }, 1000);
  };

  const handleReplay = () => {
    setIsFadingOut(true);

    setTimeout(() => {
      navigate("/start");
    }, 1000);
  };

  return (
    <>
      <div className="flex flex-col  h-screen">
        <div className="text-xl font-thin text-center text-blue-600 mb-4">
          <div style={styles.typewriter}>
            {
              <Typewriter
                options={{
                  strings: [
                    `Welcome to healthChat${userInfo.name ? `, ${userInfo.name}` : ""}`,
                  ],
                  autoStart: true,
                  loop: false,
                  deleteSpeed: Infinity,
                  pauseFor: 99999,
                  cursor: "",
                }}
              />
            }
          </div>
        </div>

        <div className="text-l font-thin text-center text-blue-600 mb-4">
          <div style={styles.typewriter}>
            {showTypewriter && (
              <Typewriter
                options={{
                  strings: ["H E A L T H"],
                  autoStart: true,
                  loop: false,
                  deleteSpeed: Infinity,
                  pauseFor: 99999,
                  cursor: "",
                }}
              />
            )}
          </div>
        </div>

        <div className="text-l font-thin text-center text-blue-600 mb-4">
          <div style={styles.typewriter}>
            {showTypewriter && (
              <Typewriter
                options={{
                  strings: [
                    "Healthy Eating & Lifestyle Transformation Hub Chatbot",
                  ],
                  autoStart: true,
                  loop: false,
                  deleteSpeed: Infinity,
                  pauseFor: 99999,
                  cursor: "",
                }}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col justify-end ">
          <div style={styles.container}>
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTKpdIqFPiB6635S_ZYYO6zm0pzqsxRaVo9A&s"
              alt="Logo"
              style={styles.logo}
            />
            <img
              src={`${process.env.PUBLIC_URL}/healthChat.jpg`}
              alt="HealthChat Logo"
              style={styles.logo}
            />
          </div>
          <div className="flex justify-center get-started-container">
            <Ripples color="rgba(255, 255, 255, 0.3)">
              <FloatingPanel>
                <button
                  onClick={handleClick}
                  className={`px-6 py-3 font-bold text-white rounded-lg shadow-md glowing-button shadow-blue-500/15 transition-opacity duration-1000 ${
                    isFadingOut ? "opacity-0" : "opacity-100"
                  }  glowing-button`}
                >
                  Get Started
                </button>
                <button
                  onClick={handleReplay}
                  className={`px-6 py-3 font-bold text-white rounded-lg shadow-md glowing-button shadow-blue-500/15 transition-opacity duration-1000 ${
                    isFadingOut ? "opacity-0" : "opacity-100"
                  }  glowing-button`}
                >
                  Replay Intro
                </button>
              </FloatingPanel>
            </Ripples>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
