import React, { useState, useEffect } from "react";
import CurrencyInput from "react-currency-input-field";
import "./WeightPicker.css";
import { upsertAnswer } from "../../util/Util";

const WeightPicker = ({
  weight,
  setWeight,
  surveyResponse,
  setSurveyResponse,
}) => {
  const [inputClass, setInputClass] = useState("input-unfocused");
  const handleWeightChange = (value) => {
    if (value === null || value === "") {
      setWeight("");
      upsertAnswer(600, "", [], setSurveyResponse);
      localStorage.setItem("userWeight", "");
      return;
    }

    if (value === ".") {
      setWeight("0.");
      upsertAnswer(600, "0.", [], setSurveyResponse);
      return;
    }

    if (value && (value.match(/\./g) || []).length > 1) {
      return;
    }

    if (value && value.endsWith(".")) {
      setWeight(value);
      upsertAnswer(600, value, [], setSurveyResponse);
      return;
    }

    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue) && parsedValue <= 199.9) {
      setWeight(parsedValue);
      upsertAnswer(600, parsedValue.toString(), [], setSurveyResponse);
      localStorage.setItem("userWeight", parsedValue.toString());
    } else {
      setWeight("");
      upsertAnswer(600, "", [], setSurveyResponse);
    }
  };

  useEffect(() => {
    const savedWeight =
      surveyResponse.survey_data.find((q) => q.questionNumber === 600)
        ?.answerString || "";
    setWeight(savedWeight);
  }, [surveyResponse, setWeight]);
  return (
    <div className="flex justify-center items-center">
      <CurrencyInput
        className={`text-center ${inputClass}`}
        style={{
          fontSize: "3rem",
          width: "55vw",
          height: "4rem",
        }}
        value={weight}
        decimalsLimit={1}
        onValueChange={handleWeightChange}
        allowNegativeValue={false}
        disableGroupSeparators={true}
        placeholder="0.0"
        onFocus={() => setInputClass("input-focused")}
        onBlur={() => {
          setInputClass("input-unfocused");
          if (weight && weight.endsWith(".")) {
            const updatedWeight = weight + "0";
            setWeight(updatedWeight);
            upsertAnswer(600, updatedWeight, [], setSurveyResponse);
            localStorage.setItem("userWeight", updatedWeight);
          }
        }}
      />
      <span className="ml-2" style={{ fontSize: "3rem" }}>
        kg
      </span>
    </div>
  );
};

export default WeightPicker;
