import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Start.css";
const Start = () => {
  const queryString = window.location.search;
  const divideSpeed = 1;
  const [fadeStage, setFadeStage] = useState("fade-in");
  const [showLogo, setShowLogo] = useState(true);
  const [showWelcome, setShowWelcome] = useState(false);
  const [showSwipeUp, setShowSwipeUp] = useState(false);
  const [sunPosition, setSunPosition] = useState(0);
  const [isFadingOut, setIsFadingOut] = useState(false);

  const navigate = useNavigate();
  const handleClick = () => {
    setIsFadingOut(true);
    setTimeout(() => {
      navigate(`/testchat${queryString}`);
    }, 1000);
  };

  useEffect(() => {
    const handleSwipeOrScroll = (e) => {
      if (sunPosition === null) {
        setSunPosition(0);
      }

      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const docHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const scrollPosition = (scrollTop / docHeight) * 100;
      setSunPosition(scrollPosition);
      setShowSwipeUp(false);
      if (sunPosition === 0) setShowSwipeUp(true);
    };

    window.addEventListener("scroll", handleSwipeOrScroll);
    window.addEventListener("touchmove", handleSwipeOrScroll);

    const fadeInTimer = setTimeout(() => {
      setFadeStage("visible");
    }, 1000 / divideSpeed);

    const fadeOutTimer = setTimeout(() => {
      setShowLogo(false);
      setShowWelcome(true);
    }, 2000 / divideSpeed);

    const swipeUpTimer = setTimeout(() => {
      setShowSwipeUp(true);
    }, 4000 / divideSpeed);

    const scrollDown = () => {
      const scrollAmount = window.innerHeight * 0.1;

      window.scrollBy({
        top: scrollAmount,
        left: 0,
        behavior: "smooth",
      });
    };
    const scrolltimer = setTimeout(scrollDown, 10000 / divideSpeed);

    return () => {
      clearTimeout(fadeInTimer);
      clearTimeout(fadeOutTimer);
      clearTimeout(swipeUpTimer);
      clearTimeout(scrolltimer);
      window.removeEventListener("scroll", handleSwipeOrScroll);
      window.removeEventListener("touchmove", handleSwipeOrScroll);
    };
  }, [sunPosition]);

  return (
    <>
      <div className={`tailwind-reset container ${fadeStage}`}>
        {showLogo && (
          <div className="logo-container">
            <img
              src={`${process.env.PUBLIC_URL}/healthChat.jpg`}
              alt="HealthChat Logo"
              className="logo"
            />
            <div className="stardust">
              <img
                src={`${process.env.PUBLIC_URL}/thumbnail/stardust.svg`}
                alt="Stardust"
              />
            </div>
            <div className="logo-text">healthChat</div>
          </div>
        )}
        {!showLogo && (
          <div className="nature-fade-in background-svg image-container">
            <img
              className="fill-background"
              src={`${process.env.PUBLIC_URL}/start2/01-welcome-nature.svg`}
              alt="Welcome Nature"
            />
          </div>
        )}
        {showWelcome && (
          <div className="welcome-text">
            <img
              src={`${process.env.PUBLIC_URL}/start2/01.1-Welcome.svg`}
              alt="Welcome to healthChat"
            />
          </div>
        )}
        {showSwipeUp && (
          <>
            <div className="swipe-up-animation">
              <img
                src={`${process.env.PUBLIC_URL}/start2/01.3-swipe-text.svg`}
                alt="Swipe Up ☝️"
              />
            </div>
          </>
        )}

        {!showLogo && sunPosition !== null && (
          <div
            className="sun"
            style={{
              transform: `translate(${sunPosition}%, ${sunPosition}%)`,
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/start2/02.0-sun.svg`}
              alt="Sun"
            />
          </div>
        )}
      </div>
      {
        <>
          <div className="second-container">
            <div className="cluster">
              <img
                className="myname"
                src={`${process.env.PUBLIC_URL}/start2/02.1-myname.jpg`}
                alt="healthChat"
              />
              <img
                className="mt-9"
                src={`${process.env.PUBLIC_URL}/start2/02.2-i-am-healthchat.svg`}
                alt="i am healtch chat"
              />
              <img
                className="myname"
                alt="healthChat"
                src={`${process.env.PUBLIC_URL}/thumbnail/svg256.svg`}
              />
            </div>
            <div className="cluster">
              <img
                className="myname"
                alt="healthChat"
                src={`${process.env.PUBLIC_URL}/start2/03.1-healthy-eating.jpg`}
              />
              <img
                src={`${process.env.PUBLIC_URL}/start2/03.2-healthchat-full.svg`}
                alt="Healthy Eating And Lifestyle Transformation Hub"
              />
            </div>
            <div className="cluster">
              <img
                className="myname"
                alt="healthChat"
                src={`${process.env.PUBLIC_URL}/start2/03.3-bot-exercise.jpg`}
              />
              <img
                className="myname"
                alt="healthChat"
                src={`${process.env.PUBLIC_URL}/start2/03.4-fat-loss.svg`}
              />
            </div>
          </div>
          <div className="bottom-cluster">
            <button
              onClick={handleClick}
              className={`px-6 py-3 font-bold text-white rounded-lg shadow-md shadow-blue-500/15 transition-opacity duration-1000 ${
                isFadingOut ? "opacity-0" : "opacity-100"
              }  glowing-button  mt-5 mx-auto block`}
            >
              Get Started Now
            </button>
            <div
              className={`version-text`}
            >{`${process.env.REACT_APP_VERSION}`}</div>
          </div>
        </>
      }
      {/*<img
      className="myname"
      alt="healthChat"
      src={`${process.env.PUBLIC_URL}/thumbnail/svg256.svg`}
      />*/}
    </>
  );
};

export default Start;
