import React, { useEffect, useState } from "react";
// import { fetchDebugData } from '../../api/ApiService';

function Debug() {
  const [data] = useState("");

  useEffect(() => {
    // fetchDebugData().then((response) => {
    //   setData(response.data);
    // }).catch((error) => {
    //   console.error('Error fetching data:', error);
    // });
  }, []);
  return (
    <div>
      <h1>Debug Page</h1>
      <p>{data}</p>
    </div>
  );
}

export default Debug;
