import React, { useEffect } from "react";
import Typewriter from "typewriter-effect";

const LifestyleChanges = ({
  setCurrentQuestion,
  saveUpsert,
  surveyResponse,
  setSurveyResponse,
}) => {
  const options = [
    "Consult doctor or dietician",
    "Exercise",
    "Follow Specific Diet/Fasting",
    "Blood Sugar Monitoring",
    "Surgery (Liposuction, Bariatric)",
    "Daily weighing",
    "Others",
  ];
  const optionDisplayMap = {
    "Consult doctor or dietician": "🩺Consult Dr/Dietician",
    Exercise: "🏃‍♀️Exercise⚽",
    "Follow Specific Diet/Fasting": "🥗Specific Diet/Fasting",
    "Blood Sugar Monitoring": "🩸Blood Sugar Monitoring",
    "Surgery (Liposuction, Bariatric)": "🔪Surgery (Lipo/Bariatric)",
    "Daily weighing": "🎯Daily weighing",
    Others: "📝Others",
  };
  const selectedOptions =
    surveyResponse.survey_data.find((q) => q.questionNumber === 900)
      ?.answerList || [];

  const otherText =
    surveyResponse.survey_data.find((q) => q.questionNumber === 900)
      ?.answerString || "";

  const upsertAnswer = (questionNumber, answerString, answerList = []) => {
    setSurveyResponse((prev) => {
      const surveyData = [...prev.survey_data];
      const index = surveyData.findIndex(
        (answer) => answer.questionNumber === questionNumber,
      );

      if (index !== -1) {
        surveyData[index] = { questionNumber, answerString, answerList };
      } else {
        surveyData.push({ questionNumber, answerString, answerList });
      }

      return { survey_data: surveyData };
    });
  };

  const handleOptionToggle = (option) => {
    const newSelectedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];
    upsertAnswer(900, otherText, newSelectedOptions);
  };

  const handleTextChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^a-zA-Z0-9\s.,'"():-]/g, "");
    if (value.length > 1000) {
      value = value.substring(0, 1000);
    }
    upsertAnswer(900, value, selectedOptions);
  };

  const isNextDisabled = () => {
    return selectedOptions.includes("Others") && otherText.trim() === "";
  };

  useEffect(() => {
    localStorage.setItem("surveyResponse", JSON.stringify(surveyResponse));
  }, [surveyResponse]);

  return (
    <div className="flex flex-col items-center mt-4">
      <div className="text-center text-xl font-thin text-blue-600">
        {!isNextDisabled() && <>Last 2..</>}
        {isNextDisabled() && (
          <Typewriter
            options={{
              strings: ["Last 2.."],
              autoStart: true,
              loop: false,
              deleteSpeed: Infinity,
              cursor: "",
            }}
          />
        )}
      </div>
      <div className="text-center text-xl font-thin text-blue-600">
        What changes have you made
        <br /> to your lifestyle🤾‍♀️ recently?
      </div>
      <div className="flex flex-wrap justify-center mt-4 gap-2">
        {options.map((option) => (
          <button
            key={option}
            onClick={() => handleOptionToggle(option)}
            className={`px-4 py-2 rounded ${
              selectedOptions.includes(option)
                ? "bg-blue-500 text-white"
                : "bg-gray-300 text-black"
            }`}
          >
            {optionDisplayMap[option]}
          </button>
        ))}
      </div>
      {selectedOptions.includes("Others") && (
        <textarea
          className="mt-4 p-2 border rounded w-3/4"
          placeholder="Please specify..."
          value={otherText}
          onChange={handleTextChange}
        />
      )}
      {/*<div className="flex justify-center mt-4">
        <div className="flex items-center justify-center w-10 h-10 bg-blue-500 text-white rounded-full">
          {selectedOptions.length}
        </div>
      </div>*/}
      <div className="flex justify-center mt-4">
        <button
          type="button"
          onClick={() => setCurrentQuestion(800)}
          className="mt-2 px-4 py-2 bg-gray-500 text-white rounded mr-2"
        >
          Back
        </button>
        <button
          type="button"
          onClick={() => {
            upsertAnswer(900, otherText, selectedOptions);
            // localStorage.setItem(
            //   "surveyResponse",
            //   JSON.stringify(surveyResponse),
            // );
            saveUpsert();
            setCurrentQuestion(1000);
          }}
          className={`mt-2 px-4 py-2 rounded ${
            isNextDisabled()
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "bg-blue-500 text-white"
          }`}
          disabled={isNextDisabled()}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default LifestyleChanges;
