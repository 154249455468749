export const calculateDailyCoins = (surveyResponse) => {
  return surveyResponse ? 10 : 0;
};

export const readSurveyResponse = () => {
  try {
    const surveyResponse = localStorage.getItem("surveyResponse");
    return surveyResponse ? JSON.parse(surveyResponse) : null;
  } catch (error) {
    console.error("Error reading survey response from localStorage:", error);
    return null;
  }
};

export const convertSurveyResponseIntoUserProfile = (surveyResponse) => {
  // Assume you have a function to get user details
  const userEmail = surveyResponse.respondentEmail;
  const userDisplayName = "User Display Name"; // Replace with actual logic to get display name
  const userNickName = surveyResponse.survey_data.find(
    (item) => item.questionNumber === 100,
  )?.answerString;
  const userGender = surveyResponse.survey_data.find(
    (item) => item.questionNumber === 200,
  )?.answerString;
  const userAgeGroup = surveyResponse.survey_data.find(
    (item) => item.questionNumber === 300,
  )?.answerString;
  const userEthnicity = surveyResponse.survey_data.find(
    (item) => item.questionNumber === 400,
  )?.answerString;

  const userPhoneCC = parseFloat(
    surveyResponse.survey_data.find((item) => item.questionNumber === 501)
      ?.answerString,
  );
  const userPhone = parseFloat(
    surveyResponse.survey_data.find((item) => item.questionNumber === 500)
      ?.answerString,
  );
  const userWeight = parseFloat(
    surveyResponse.survey_data.find((item) => item.questionNumber === 600)
      ?.answerString,
  );
  const userHeight = parseFloat(
    surveyResponse.survey_data.find((item) => item.questionNumber === 700)
      ?.answerString,
  );
  const userWaistCircumference = parseFloat(
    surveyResponse.survey_data.find((item) => item.questionNumber === 800)
      ?.answerString,
  );
  const calculate_bmi = (weight, height) => {
    if (height <= 0) return -1;
    return weight / (height * height);
  };

  const userBMI = calculate_bmi(userWeight, userHeight);

  return {};
};
