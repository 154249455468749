import React, { useState, useEffect } from "react";
import CurrencyInput from "react-currency-input-field";
import "./HeightPicker.css";
import { upsertAnswer } from "../../util/Util";

const HeightPicker = ({
  height,
  setHeight,
  surveyResponse,
  setSurveyResponse,
}) => {
  const [inputClass, setInputClass] = useState("input-unfocused");
  const handleHeightChange = (value) => {
    if (typeof value === "undefined" || value === null || value === "") {
      setHeight("");
      localStorage.setItem("userHeight", "");
      upsertAnswer(700, height.toString(), [], setSurveyResponse);
      return;
    }

    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue) && parsedValue <= 300) {
      setHeight(parsedValue);
      localStorage.setItem("userHeight", parsedValue.toString());
      upsertAnswer(700, height.toString(), [], setSurveyResponse);
    }
  };

  useEffect(() => {
    const storedHeight = localStorage.getItem("userHeight");
    if (storedHeight) {
      setHeight(parseInt(storedHeight, 10));
    } else setHeight(170);
  }, [setHeight]);

  return (
    <div className="flex justify-center items-center">
      <CurrencyInput
        className={`text-center ${inputClass}`}
        style={{
          fontSize: "3rem",
          width: "55vw",
          height: "4rem",
        }}
        value={height}
        decimalsLimit={0}
        onValueChange={handleHeightChange}
        allowNegativeValue={false}
        disableGroupSeparators={true}
        placeholder="0"
        onFocus={() => setInputClass("input-focused")}
        onBlur={() => setInputClass("input-unfocused")}
      />
      <span className="ml-2" style={{ fontSize: "3rem" }}>
        cm
      </span>
    </div>
  );
};

export default HeightPicker;
